<template>
  <el-container style="min-height: 100vh;">
    <el-aside min-width="20%">
      <div>
        <el-tree
            :props="TreeProps"
            :load="loadNode"
            lazy
            ref="tree"
            @node-click="addTab"
        ></el-tree>
      </div>

    </el-aside>
    <el-main>
      <el-tabs
          v-model="editableTabsValue"
          type="card"
          closable
          @tab-click="chooseTab"
          @tab-remove="removeTab"
          style="min-width: 100%; min-height: 100%"
      >
        <el-tab-pane
            v-for="(item, index) in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
        >
          <div class="tabNav">
            <el-date-picker
                v-model="item.timeValue"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="shortcuts"
            >
            </el-date-picker>
            <el-button type="primary" style="margin-left: 15px" @click="searchDataWithValid">查询</el-button>
          </div>
          <div>
            <el-table
                :data="currentTab.tableData"
                v-loading="dataListLoading"
                @header-click="more"
            >
              <el-table-column prop="rowKey" label="日期" min-width="100%" :sortable="true" >
                <template slot-scope="scope">
                  {{ currentTab.tableData[scope.$index].rowKey }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="properties"
                  :label="item.name"
                  align="center"
                  v-for="(item, index) in currentTab.tableHeader"
                  :key="item.mpId"
              >
                <el-table-column label="是否存在问题" min-width="100%">
                  <template slot-scope="scope">
                    <div
                        :style="
                  currentTab.tableData[scope.$index].Isproblem[index] == '是'
                    ? 'color:#FF0000;'
                    : 'color:#000000;'
                "
                    >
                      {{ currentTab.tableData[scope.$index].Isproblem[index] }}
                    </div>

                    <!-- {{tableData[scope.$index].properties[index].isProblem}} -->
                  </template>
                </el-table-column>
                <el-table-column label="治理前" min-width="100%">
                  <template slot-scope="scope">
                    {{ currentTab.tableData[scope.$index].before[index] }}
                    <!-- {{tableData[scope.$index].properties[index].before}} -->
                  </template>
                </el-table-column>
                <el-table-column label="治理后" min-width="100%">
                  <template slot-scope="scope">
                    {{ currentTab.tableData[scope.$index].after[index] }}
                    <!-- {{tableData[scope.$index].properties[index].after}} -->
                  </template>
                </el-table-column>
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="更多"-->
<!--                  header-align="center"-->
<!--                  align="center"-->
<!--                  min-width="100%"-->
<!--                  v-if="currentTab.moreVisible"-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  <el-button type="text" size="small" @click="more(scope.row)"-->
<!--                  >...</el-button>-->
<!--                </template>-->
              </el-table-column>
            </el-table>
<!--            <el-pagination-->
<!--                background-->
<!--                @current-change="currentChange"-->
<!--                @size-change="sizeChange"-->
<!--                :current-page="currentTab.page"-->
<!--                :page-size="currentTab.size"-->
<!--                layout="sizes, prev, next, slot"-->
<!--                :total="currentTab.total"-->
<!--            >-->
<!--            </el-pagination>-->
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import { pinyin } from "pinyin-pro";
import dayjs from "dayjs"
export default {
  name: "dataSearch",
  data() {
    return {
      tableHeaderTest: [],
      TreeProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf',
      },
      editableTabsValue: '0',
      editableTabs: [],
      currentTab: [],
      tabIndex: 0,

      timeValue: '',
      dateValue: '',
      tableData: [],
      tableHeader: [],
      dataListLoading: false,

      page: 1,
      size: 10,
      total: 0,

      //新增
      currOperator: "",
      existLevel: [],

      shortcuts: [
        {
          text: '最近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '最近一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '最近三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ]
    }
  },
  methods: {
    more(column, event) {
      if (column.label === "更多") {
        console.log(column, event)
        this.currentTab.mpPage += 1;
        this.sizeChange(this.currentTab.size += 10);
        this.getRequest("/universaltable/getOtherLeafItemWithLimit?tableId="
            + this.currentTab.tabId + "&id="+this.currentTab.nodeId+"&page=" + this.currentTab.mpPage
        + "&size=" + this.currentTab.size).then((resp) => {
          if (resp) {
            for (let i = 0; i < JSON.parse(JSON.stringify(resp.data.data)).length; i++) {
              this.currentTab.tableHeader.push(JSON.parse(JSON.stringify(resp.data.data))[i]);

              if (resp.data.total < this.currentTab.size * this.currentTab.page) {
                this.currentTab.moreVisible = false;
              } else {
                this.currentTab.moreVisible = true;
              }
            }
          }
        });
      }
    },
    currentChange(currentPage) {
      this.currentTab.page = currentPage;
      this.searchData();
    },
    sizeChange(currentSize) {
      this.currentTab.size = currentSize;
      this.searchData();
    },
    removeTab(targetName) {
      let tabs = this.editableTabs
      let activeName = this.editableTabsValue
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
              this.currentTab = nextTab
            }
          }
        })
      }
      this.editableTabsValue = activeName
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName)
    },
    addTab(node) {
      console.log(node)
      //每点击一个节点，就把改节点下所有的测点展示在右侧
      //跳过项目节点
      if(node.treeNodeDeep > 1){
        let flag = true;
        //判断是否被点过，只需比较levelId
        this.editableTabs.find((item) => {
          if (item.tabId == node.levelId && item.nodeId == node.id) {
            this.currentTab = item
            this.editableTabsValue = item.name
            flag = false
            console.log("false:"+item.tabId+"node.id:"+node.id)
          }
        })
        if(flag === true){
          this.loadTableHeader(node.id, node.name, node.hbaseTableName, node.levelId, node.treeNodeDeep);
        }
      }
      //旧版本代码
      // let flag = true
      // if (node.hasOwnProperty('leaf')) {
      //   this.editableTabs.find((item) => {
      //     if (item.tabId == node.id) {
      //       this.currentTab = item
      //       this.editableTabsValue = item.name
      //       flag = false
      //     }
      //   })
      //   if (flag === true) {
      //     this.loadTableHeader(node.id, node.name, node.hbasetablename);
      //   }
      // }
    },
    //已修改为新接口，添加了tableHeaderPinyin用于存储表头数据项的后端转拼音结果
    //获取一个非叶子节点下所有的非叶子节点并存放到editableTabs中
    loadTableHeader(id, name, hbaseTableName, levelId, treeDeepNode) {
      console.log("hbasename:::"+hbaseTableName+"levelId:::"+levelId+"name:::"+name)
        // this.postRequest("/universaltable/getOtherLeafItemWithLimit?tableId="+levelId+"&id="+id+"&page=1"+"&size=10").then(resp => {
        this.postRequest("/universaltable/getOtherLeafItem?tableId="+levelId+"&id="+id).then(resp => {
          if(resp){
            this.tableHeaderTest = resp.data.data
            console.log("resp:::",JSON.stringify(resp.data))
            this.total = resp.data.count;
            console.log("this.count:::"+this.total)
            let newTabName = ++this.tabIndex + ''
            var t = new Date()
            var ts = t.getTime()
            console.log(t.getTime())
            console.log(dayjs(t.getTime()).format('YYYY-MM-DD HH:mm:ss') + "")
            t.setTime(ts - 60 * 10 * 1000)
            this.editableTabs.push({
              nodeId:id,
              tabId: levelId,
              title: name,
              name: newTabName,
              hbaseTableName: hbaseTableName,
              page: 1,
              size: 10,
              total: 100000,
              mpPage: 1,
              moreVisible: false,
              timeValue: [
                dayjs(t.getTime()).format('YYYY-MM-DD HH:mm:ss') + "",
                dayjs().format('YYYY-MM-DD HH:mm:ss') + ""
              ],
              content: 'New Tab content',
              tableHeader: JSON.parse(JSON.stringify(resp.data.data)),
              tableHeaderPinyin: resp.data.pinyin,
              tableData: []
            })
            this.editableTabsValue = newTabName
            this.editableTabs.find((item) => {
              if (item.name === this.editableTabsValue) {
                this.currentTab = item
              }
            })
            //是否显示更多
            if (this.total > this.currentTab.size) {
              this.currentTab.moreVisible = true;
            }
          }
        }).then(error => {
        })
      // }
      // this.getRequest("/measurePointManage/getMeasurePointInfoBySubEquipmentIdWithPinyin/?subEquipmentId=" + id).then((resp) => {
      //   if (resp) {
      //     this.total = resp.data.total;
      //     let newTabName = ++this.tabIndex + ''
      //     var t = new Date()
      //     var ts = t.getTime()
      //     console.log(t.getTime())
      //     console.log(dayjs(t.getTime()).format('YYYY-MM-DD HH:mm:ss') + "")
      //     t.setTime(ts - 60 * 10 * 1000)
      //     this.editableTabs.push({
      //       tabId: id,
      //       title: name,
      //       name: newTabName,
      //       hbaseTableName: hbaseTableName,
      //       page: 1,
      //       size: 10,
      //       total: 100000,
      //       mpPage: 1,
      //       moreVisible: false,
      //       timeValue: [
      //         dayjs(t.getTime()).format('YYYY-MM-DD HH:mm:ss') + "",
      //         dayjs().format('YYYY-MM-DD HH:mm:ss') + ""
      //       ],
      //       content: 'New Tab content',
      //       tableHeader: JSON.parse(JSON.stringify(resp.data.data)),
      //       tableHeaderPinyin: resp.data.pinyin,
      //       tableData: []
      //     })
      //     this.editableTabsValue = newTabName
      //     this.editableTabs.find((item) => {
      //       if (item.name === this.editableTabsValue) {
      //         this.currentTab = item
      //       }
      //     })
      //     //是否显示更多
      //     if (this.total > this.currentTab.size) {
      //       this.currentTab.moreVisible = true;
      //     }
      //   }
      // });
    },
    chooseTab() {
      this.editableTabs.find((item) => {
        if (item.name === this.editableTabsValue) {
          this.currentTab = item
        }
      })
      console.log(this.currentTab)
    },
    searchDataWithValid() {
      this.getRequest(
          "/hbase/isHbaseTableExists/?hbaseTableName=" +
          this.currentTab.hbaseTableName
      ).then((resp) => {
        console.log("hbaseTableName:"+this.currentTab.hbaseTableName)
        if (resp.data.msg === "success") {
          this.searchData()
        }
      })
    },
    searchData() {
      console.log(this.currentTab)
      if (!this.currentTab.hasOwnProperty('timeValue') || this.currentTab.timeValue == null) {
        this.$message({
          type: 'info',
          message: '请选择时间'
        });
      } else {
        this.dataListLoading = true
        this.getRequest("/hbase/getHbaseTableData?hbaseName=" + this.currentTab.hbaseTableName
            + "&startRowKey=" + this.currentTab.timeValue[0]
            + "&endRowKey=" + this.currentTab.timeValue[1]
            + "&pageIndex=" + this.currentTab.page
            + "&pageSize=" + this.currentTab.size
        ).then((resp) => {
          if (resp.data) {
            console.log("resp.data");
            console.log(resp.data);
            var arr = [];
            // var pronumn = Object.keys(resp.data[0]).length;
            //直接用in会导致多出一个元素，改为长度计数
            for (let i = 0; i < resp.data.length; i++) {
              var o = {
                before: new Array(this.currentTab.tableHeader.length).fill(" "),
                after: new Array(this.currentTab.tableHeader.length).fill(" "),
                Isproblem: new Array(this.currentTab.tableHeader.length).fill(" "),
              };
              o["rowKey"] = resp.data[i].date;

              // for(var j = 0;j<pronumn/2;j++){
              for (var j = 0; j < this.currentTab.tableHeader.length; j++) {
                for (var key in resp.data[i]) {
                  console.log("key:" + key);
                  // console.log("this.currentTab.tableHeaderPinyin[j]:" + this.currentTab.tableHeaderPinyin[j])
                  if (
                      this.confirmEnding(key, "before") &&
                      // key.indexOf(
                      //         this.currentTab.tableHeaderPinyin[j]
                      //     // pinyin(this.currentTab.tableHeader[j].name, {
                      //     //   toneType: "none",
                      //     // }).replace(/\s*/g, "")
                      // ) == 0
                      key.slice(0, -7) === this.currentTab.tableHeaderPinyin[j]
                      // key.slice(0, -7) === this.currentTab.tableHeader[j].name
                  ) {
                    // o.before.push(resp.data[i].properties[key]);
                    // j是索引，1是个数
                    // console.log(resp.data[i])
                    // o.before.splice(j, 1, eval("resp.data[i]." + key));
                    console.log("进入了before")
                    o.before.splice(j, 1, resp.data[i][key]);
                  }
                  if (
                      this.confirmEnding(key, "after") &&
                      // key.indexOf(
                      //         this.currentTab.tableHeaderPinyin[j]
                      //     // pinyin(this.currentTab.tableHeader[j].name, {
                      //     //   toneType: "none",
                      //     // }).replace(/\s*/g, "")
                      // ) == 0
                      key.slice(0, -6) === this.currentTab.tableHeaderPinyin[j]
                      // key.slice(0, -6) === this.currentTab.tableHeader[j].name
                  ) {
                    // o.after.splice(j, 1, eval("resp.data[i]." + key));
                    console.log("进入了after")
                    o.after.splice(j, 1, resp.data[i][key]);
                    // resp.data[i][key]
                    // o.after.push(resp.data[i].properties[key]);
                  }
                }
              }
              console.log("循环结束")
              for (var k = 0; k < this.currentTab.tableHeader.length; k++) {
                if (o.before[k] !== "") {
                  o.Isproblem.splice(k, 1, o.before[k] == o.after[k] ? "否" : "是")
                  // o.Isproblem.push(o.before[k] == o.after[k] ? "否" : "是");
                }

              }
              console.log("0", o)
              arr.push(o);
              //  arr.splice(o);
            }
            console.log("arr");
            console.log(arr);
            this.currentTab.tableData = arr;
            console.log(this.currentTab);
            console.log("key")
            this.dataListLoading = false
          }
        })
      }
    },
    confirmEnding(str, target) {
      var start = str.length - target.length;
      var arr = str.substr(start, target.length);
      if (arr == target) {
        return true;
      }
      return false;
    },
    loadNode(node, resolve) {
      //层级变动后的代码
      //第一层级：展示项目信息
      if (node.level === 0) {
        let data = []
        this.postRequest("/levelManage/projectattributes/getProjectWithUid?uid=" + this.currOperator).then(response => {
          console.log("后端返回的项目信息：" + JSON.stringify(response.data))
          //{"msg":"success","code":0,"data":[{"name":"project1","disabled":"2","id":3},{"name":"qwer","disabled":"0","id":4}]}
          for (let i = 0; i < response.data.data.length; i++) {
            response.data.data[i].icon = 'iconfont icon-xuanze2'
            console.log(response.data.data[i].name + "  " + response.data.data[i].disabled)
            if (response.data.data[i].disabled == 0) {
              //isdisabled为1代表未定义层级关系
              response.data.data[i].isdisabled = 1
            } else {
              response.data.data[i].isdisabled = 0
            }
            response.data.data[i].treeNodeDeep = 1
            data.push(response.data.data[i])
          }
          return resolve(data);
        })
      }

      //第二层级：分两步：有子层级的和没有子层级的（若为叶子节点需要加上标志，因为后面node_click的时候用此判断）
      //注意：叶子节点用subEquipment标志，为了复用以前的代码
      if (node.level === 1) {
        console.log("node.data.isdisabled:" + node.data.isdisabled)
        if (node.data.isdisabled == 1) return resolve([])
        else {
          //先渲染非叶子节点
          let data = []
          this.postRequest("/universaltable/getRootLevel?id=" + node.data.id).then(response => {
            console.log("第二层非叶子节点信息：" + JSON.stringify(response.data))
            for (let j = 0; j < response.data.data.length; j++) {
              response.data.data[j].icon = 'iconfont icon-xuanze2'
              response.data.data[j].flag = 'subEquipment'
              // response.data.data[j].leaf = true
              response.data.data[j].levelId = response.data.levelId
              response.data.data[j].treeNodeDeep = 2
              data.push(response.data.data[j])
            }
            console.log("testPoint1 data:" + data)
            return resolve(data);
          })
        }
      }

      //第三层级及往下使用相同逻辑渲染
      if (node.level > 1) {
        for (let i = 2; i < 50; i++) {
          if (node.level === i) {
            //先渲染非叶子节点
            let data = []
            this.postRequest("/universaltable/getSonLevel?tableId=" + node.data.levelId + "&id=" + node.data.id).then(response => {
              console.log("第三层非叶子节点信息：" + JSON.stringify(response.data))
              for (let j = 0; j < response.data.data.length; j++) {
                response.data.data[j].icon = 'iconfont icon-xuanze2'
                response.data.data[j].flag = 'subEquipment'
                // response.data.data[j].leaf = true
                response.data.data[j].levelId = response.data.levelId
                response.data.data[j].treeNodeDeep = i + 1
                data.push(response.data.data[j])
              }
              console.log("testPoint1 data:" + data)
              return resolve(data);
            })
          }
        }
      }
    },
  },
  created(){
    /**
     * 初始化操作
     */
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    console.log("userId:"+this.currOperator)
    // this.currOperatorName = JSON.parse(
    //     window.sessionStorage.getItem("user")
    // ).username;
  }
}
            //原版代码
      // if (node.level === 0) {
      //   let data = []
      //   this.getRequest("/dataList/getAllCoal/").then((resp) => {
      //     if (resp.data) {
      //       for (let i = 0; i < resp.data.length; i++) {
      //         console.log(data)
      //         resp.data[i]["name"] = resp.data[i]["coal"]
      //         data.push(resp.data[i])
      //       }
      //       console.log(data)
      //       return resolve(data)
      //     }
      //   });
      // }
      // if (node.level === 1) {
      //   let data = []
      //   this.getRequest("/coalManage/getSystemInfoByCoalId?coalId=" + node.data.id).then((resp) => {
      //     if (resp.data) {
      //       for (let i = 0; i < resp.data.length; i++) {
      //         data.push(resp.data[i])
      //       }
      //       return resolve(data)
      //     }
      //   });
      // }
      // if (node.level === 2) {
      //   let data = []
      //   this.getRequest("/coalManage/getSubSystemInfoBySystemId?systemId=" + node.data.id).then((resp) => {
      //     if (resp.data) {
      //       for (let i = 0; i < resp.data.length; i++) {
      //         data.push(resp.data[i])
      //       }
      //       return resolve(data)
      //     }
      //   });
      // }
      // if (node.level === 3) {
      //   let data = []
      //   this.getRequest("/coalManage/getEquipmentInfoBySubSystemId?subSystemId=" + node.data.id).then((resp) => {
      //     if (resp.data) {
      //       for (let i = 0; i < resp.data.length; i++) {
      //         data.push(resp.data[i])
      //       }
      //       return resolve(data)
      //     }
      //   });
      // }
      // if (node.level === 4) {
      //   let data = []
      //   this.getRequest("/coalManage/getSubEquipmentInfoByEquipmentId?equipmentId=" + node.data.id).then((resp) => {
      //     if (resp.data) {
      //       for (let i = 0; i < resp.data.length; i++) {
      //         resp.data[i].leaf = true
      //         data.push(resp.data[i])
      //       }
      //       return resolve(data)
      //     }
      //   });
      // }
      // if (node.level >= 5) {
      //   // this.addTab(node)
      //   return resolve([])
      // }

</script>

<style scoped>
.tabNav {
  height: 40px;
}
</style>
